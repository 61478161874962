import { Session } from 'next-auth'
import {
  Customer,
  Result,
  ChangePasswordOptions,
  CustomerAddress,
  UpdateCustomerRequest,
  SavePaymentMethodRequest,
  DeactivateAccount,
  CustomerRewards,
} from 'shared-types'
import { ApiError } from '~/helpers/apiError'
import { IAccountClient } from '~/types/Client'
import { request } from './request'

export class AccountClient implements IAccountClient {
  public updateAccount = async (
    payload: CustomerAddress,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request<Customer>(
      {
        url: '/bff/account/customer',
        method: 'PUT',
        body: payload,
      },
      session
    )
  }

  public changePassword = async (
    payload: ChangePasswordOptions,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/change/password',
        method: 'PUT',
        body: payload,
      },
      session
    )
  }

  public addAddress = async (
    payload: CustomerAddress,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/address',
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  public updateAddress = async (
    payload: CustomerAddress,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/address',
        method: 'PUT',
        body: payload,
      },
      session
    )
  }

  public deleteAddress = async (
    id: string,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: `/bff/account/address/${id}`,
        method: 'DELETE',
      },
      session
    )
  }

  public updateCustomerPreferences = async (
    payload: UpdateCustomerRequest,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/customer/preferences',
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  public getCustomer = async (
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/details',
        method: 'GET',
      },
      session
    )
  }

  public addPaymentMethods = async (
    payload: SavePaymentMethodRequest,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/payment-method',
        method: 'POST',
        body: payload,
      },
      session
    )
  }

  public updatePaymentMethods = async (
    refId: string,
    payload: SavePaymentMethodRequest,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: `/bff/account/payment-method/${refId}`,
        method: 'PUT',
        body: payload,
      },
      session
    )
  }

  public deletePaymentMethods = async (
    id: string,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: `/bff/account/payment-method/${id}`,
        method: 'DELETE',
      },
      session
    )
  }

  public addGiftCard = async (
    code: string,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/gift-card',
        method: 'POST',
        body: { code },
      },
      session
    )
  }

  public deleteGiftCards = async (
    id: string,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: `/bff/account/gift-card/${id}`,
        method: 'DELETE',
      },
      session
    )
  }

  public deactivateAccount = async (
    payload: DeactivateAccount,
    session: Session
  ): Promise<Result<Customer, ApiError>> => {
    return request(
      {
        url: '/bff/account/customer/deactivate',
        method: 'PATCH',
        body: payload,
      },
      session
    )
  }

  public getLoyaltyPoints = async (
    session: Session
  ): Promise<Result<CustomerRewards, ApiError>> => {
    return request(
      {
        url: '/bff/account/loyalty-points',
        method: 'GET',
      },
      session
    )
  }
}
